.TextArea {
    width: 100%;
    border: 1rem solid #424242;
    background-color: #2e2e2e;
    border-radius: 3rem;
    padding: 2rem 3rem;
}

.TextArea textarea {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    font-size: 7.5rem;
    line-height: 10rem;
    border: 0;
    color: #c9c9c9;
    outline: none;
    background-color: transparent;
    resize: none;
}