.Wrapper {
    display: flex;
    width: calc(12rem* 4 - 2rem);
    height: 10rem;
}

.Wrapper>div {
    width: 10rem;
    height: 10rem;
    margin-right: 2rem;
    background-size: 30rem;
}

.Wrapper>div:last-child {
    margin-right: 0;
}

.Metamask {
    background-position-y: 0rem;
}

.Phantom {
    background-position-y: 20rem;
}

.Chrome {
    background-position-y: 30rem;
}

.Blowfish {
    background-position-y: 10rem;
}

.IsBad {
    background-position-x: 20rem;
}

.IsGood {
    background-position-x: 0rem;
}

.IsUndefined {
    background-position-x: 10rem;
}