.Wrapper {
    overflow: hidden;
}
.Wrapper>div {
    background-color: #242424;
}

.Title {
    color: #828282;
    font-size: 8rem;
    font-weight: bold;
    text-transform: uppercase;
}

.Count {
    margin-top: 10rem;
    color: #c9c9c9;
    font-weight: bold;
    font-size: 12rem;
}

.About {
    margin-top: 5rem;
    font-size: 6rem;
    color: #828282;
}