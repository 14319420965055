.Buttons {
    display: flex;
}

.Buttons>div {
    margin-left: 3rem;
}

.Buttons>div:first-child {
    margin-left: 0 !important;
}