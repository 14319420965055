.BearLoader {
    width: 160em;
    height: 185em;
    position: relative;
    background: #fff;
    border-radius: 100em 100em 0 0;
}

.BearLoader:after {
    content: "";
    position: absolute;
    width: 100em;
    height: 125em;
    left: 50%;
    top: 25em;
    transform: translateX(-50%);
    background-image: radial-gradient(circle, #000 48%, transparent 55%),
        radial-gradient(circle, #000 48%, transparent 55%),
        radial-gradient(circle, #fff 30%, transparent 45%),
        radial-gradient(circle, #000 48%, transparent 51%),
        linear-gradient(#000 20em, transparent 0),
        linear-gradient(#dfdfdf 60em, transparent 0),
        radial-gradient(circle, #dfdfdf 50%, transparent 51%),
        radial-gradient(circle, #dfdfdf 50%, transparent 51%);
    background-repeat: no-repeat;
    background-size: 16em 16em, 16em 16em, 10em 10em, 42em 42em, 12em 3em,
        50em 25em, 70em 70em, 70em 70em;
    background-position: 25em 10em, 55em 10em, 36em 44em, 50% 30em, 50% 85em,
        50% 50em, 50% 22em, 50% 45em;
    animation: faceLift 3s linear infinite alternate;
}

.BearLoader:before {
    content: "";
    position: absolute;
    width: 140%;
    height: 125em;
    left: -20%;
    top: 0;
    background-image: radial-gradient(circle, #fff 48%, transparent 50%),
        radial-gradient(circle, #fff 48%, transparent 50%);
    background-repeat: no-repeat;
    background-size: 65em 65em;
    background-position: 0em 12em, 145em 12em;
    animation: earLift 3s linear infinite alternate;
}

@keyframes faceLift {
    0% {
        transform: translateX(-60%);
    }

    25% {
        background-image: radial-gradient(circle, #1971c2 48%, transparent 55%),
            radial-gradient(circle, #1971c2 48%, transparent 55%),
            radial-gradient(circle, #fff 30%, transparent 45%),
            radial-gradient(circle, #000 48%, transparent 51%),
            linear-gradient(#000 20em, transparent 0),
            linear-gradient(#dfdfdf 60em, transparent 0),
            radial-gradient(circle, #dfdfdf 50%, transparent 51%),
            radial-gradient(circle, #dfdfdf 50%, transparent 51%);
    }

    50% {
        background-image: radial-gradient(circle, #1971c2 48%, transparent 55%),
            radial-gradient(circle, #1971c2 48%, transparent 55%),
            radial-gradient(circle, #fff 30%, transparent 45%),
            radial-gradient(circle, #000 48%, transparent 51%),
            linear-gradient(#000 20em, transparent 0),
            linear-gradient(#dfdfdf 60em, transparent 0),
            radial-gradient(circle, #dfdfdf 50%, transparent 51%),
            radial-gradient(circle, #dfdfdf 50%, transparent 51%);
    }
    
    75% {
        background-image: radial-gradient(circle, #1971c2 48%, transparent 55%),
            radial-gradient(circle, #1971c2 48%, transparent 55%),
            radial-gradient(circle, #fff 30%, transparent 45%),
            radial-gradient(circle, #000 48%, transparent 51%),
            linear-gradient(#000 20em, transparent 0),
            linear-gradient(#dfdfdf 60em, transparent 0),
            radial-gradient(circle, #dfdfdf 50%, transparent 51%),
            radial-gradient(circle, #dfdfdf 50%, transparent 51%);
    }

    100% {
        transform: translateX(-30%);
    }
}

@keyframes earLift {
    0% {
        transform: translateX(10em);
    }

    100% {
        transform: translateX(0em);
    }
}