.Wrapper {
    width: 100%;
    background: #2e2e2e;
    border-radius: 6rem;
}

.Wrapper.Empty {
    background-color: transparent;
    border: 1rem solid #424242;
}

.Header {
    padding-right: 8rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.Title {
    margin-right: auto;
    padding: 8rem 0 8rem 8rem;
    font-size: 11rem;
    color: #c9c9c9;
}

.Padding {
    padding: 8rem;
}

.Line {
    height: 1rem;
    width: 100%;
    background-color: #424242;
}

.Padding > div {    
    margin-bottom: 5rem;
}