.Wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-Content: center;
    align-items: center;
    flex-direction: column
}

.Text {
    line-height: 16rem;
    text-align: center;
    font-size: 13rem;
    color: white
}