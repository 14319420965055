.Wrapper {
    top: 0;
    left: -100%;
    bottom: 0;
    overflow: auto;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #242424;
    animation: show 0.7s normal forwards ease-in-out;
}

@keyframes show {
    from {
        left: -100%;
    }

    to {
        left: 0;
    }
}

.Hide {
    animation: hide 0.6s normal forwards ease-in-out;
}

@keyframes hide {
    from {
        left: 0;
    }

    to {
        left: -100%;
    }
}

.Header {
    align-items: center;
    padding: 8rem;
    display: flex;
}

.Line {
    height: 1rem;
    width: 100%;
    background-color: #424242;
}

.Title {
    font-size: 14rem;
    color: #c9c9c9;
}

.Close {
    position: relative;
    cursor: pointer;
    margin-left: auto;
    width: 18rem;
    height: 18rem;
}

.Close::before {
    position: absolute;
    top: 50%;
    display: block;
    content: '';
    height: 1rem;
    width: 100%;
    background-color: white;
    transform: rotate(-45deg);
}

.Close::after {
    position: absolute;
    top: 50%;
    display: block;
    content: '';
    height: 1rem;
    width: 100%;
    background-color: white;
    transform: rotate(45deg);
}

.List {
    display: flex;
    flex-direction: column;
    padding: 7rem 0;
}

.Link {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10rem 8rem;
    font-size: 10rem;
    color: white;
}

.Link:hover {
    background-color: #2e2e2e;
}

.Link.Active::after {
    content: '';
    margin-left: auto;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    background-color: #1971c2;
}