.Wrapper {
    margin-top: 15rem;
}

.Wrapper > div{
    position: relative;
    padding: 10rem;
}

.Prize {
    display: flex;
    width: 100%;
}

.Prize .Round {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9rem;
    font-weight: bold;
    position: absolute;
    top: -15rem;
    width: 30rem;
    background-color: #1971c2;
    height: 30rem;
    color: #ece9e9;
    border-radius: 100%;
}

.Prize .AnimateLine {
    position: absolute;
    top: -18rem;
    left: 0;
    right: 0;
    z-index: 1;
    width: 61%;
    height: 15rem;
    margin: 0 auto;

    transform: scale(-1, 1);
}

.LineSvg {
    stroke: white;
    stroke-dasharray: 40;
    stroke-dashoffset: 0;
    animation: line-animate 10s linear infinite;
}

@keyframes line-animate {
    100% {
        stroke-dashoffset: 500;
    }
}

.Prize .Round.Next {
    right: 15rem;
}

.Prize .Round.Actual {
    left: 15rem;
}

.Name {
    text-align: center;
    font-size: 8rem;
    margin-top: 10rem;
    font-weight: bold;
    color: #c9c9c9;
}

.StatName {
    margin-top: 2rem;
    font-size: 6rem;
    color: #828282;
    text-align: center;
}

.Box {
    margin-top: 15rem;
    display: flex;
    flex-direction: column;
}

.Top {
    display: flex;
}

.Top .ProgressText {
    font-size: 6rem;
    color: #828282;
}

.Top .Percent {
    font-size: 6rem;
    color: #828282;
    margin-left: auto;
}

.Line {
    margin-top: 5rem;
    overflow: hidden;
    width: 100%;
    height: 5rem;
    border-radius: 4rem;
    background-color: #424242;
}

.Line .Back {
    background-color: #1971c2;
    height: 5rem;
}

.Bottom {
    margin-top: 12rem;
    display: flex;
    align-items: center;
}

.Bottom .Info {
    color: #c9c9c9;
    font-size: 6.5rem;

}

.Bottom .Left {
    display: flex;
    background-color: #1971c2;
    height: 8rem;
    padding: 0 5rem;
    border-radius: 6rem;
    margin-left: auto;
    color: #ece9e9;
    font-size: 6rem;
    text-transform: uppercase;
    font-weight: bold;
    align-items: center;
}