.Note {
    line-height: 10rem;
    font-size: 8rem;
    color: #828282;
}

.Note span {
    font-weight: bold;
    color: #c9c9c9;
}

.Point {
    margin-top: 2rem;
}

.Point span {
    font-weight: bold;
}