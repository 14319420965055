.Point {
    display: flex;
    width: 100%;
}

.Name {
    color: #828282;
    width: 50%;
    font-size: 7.5rem;
}

.Value {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #c9c9c9;
    display: flex;
    justify-content: right;
    width: 50%;
    font-weight: bold;
    font-size: 7.5rem;
    overflow-wrap: anywhere;
    text-align: right;
}

.Click {
    text-decoration: underline;
    color: #1971c2;
    cursor: pointer;
}

.Click:hover {
    opacity: 0.8;
}

.Centred .Value {
    display: flex;
    align-items: center;
}