.Wrapper {
    padding-top: 45rem;
    padding-bottom: 10rem;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    height: auto;
}

.Scrolled {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

}

.Scrolled::-webkit-scrollbar {
    display: none;
}

.Wrapper > div {
    margin-top: 8rem;
}