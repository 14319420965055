.Blue {
    color: #1971c2;
}

.Green {
    color: green;
}

.Orange {
    color: orange;
}

.Red {
    color: red;
}