.Inputs {
    display: flex;
}

.Inputs >div:first-child{
    margin-right: 5rem;
}

.Inputs >div:last-child{
    width: 30rem;
}

.Delete {
    color: #1971c2;
    cursor: pointer;
    margin-left: 5rem;
    display: inline-flex;
    font-size: 6rem;
    font-weight: bold;
    margin-top: -2.5rem;
}