.Button {
    margin-bottom: 10rem;
}

.Arrow {
    height: 9rem;
}

.Arrow svg {
    width: 100%;
    height: 100%;
}

.Arrow path {
    fill: #1971c2;
}