.Wrapper {

}

.Buttons {
    margin-left: 7rem;
    display: flex;
    margin-bottom: -0.5rem;
}

.Button {
    cursor: pointer;
    color: #c9c9c9;
    padding: 5rem 7rem;
    font-size: 8.5rem;
    border-bottom: 1rem solid transparent;
}

.Button:hover {
    color: white;
}

.Button.Active {    
    color: white;
    border-bottom: 1rem solid #1971c2;
}