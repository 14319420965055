@font-face {
  font-family: "helvetica";
  src: url("fonts/HELV.ttf") format("woff"),
    url("fonts/HELV.ttf") format("opentype"),
    url("fonts/HELV.ttf") format("truetype");
}

html {
  overflow: hidden;
  font-size: calc(1vw / 2.3);
}

* {
  font-family: "helvetica";
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  letter-spacing: 0.5rem;
}