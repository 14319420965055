.Wrapper {
    display: flex;
}

.Left {
    width: 25rem;
    padding-right: 5rem;
}

.Right {
    width: calc(100% - 5rem);
}

.Num {
    margin: 0 auto;
    border-radius: 100%;
    width: 20rem;
    background-color: #1971c2;
    height: 20rem;
    color: #ece9e9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 7rem;
    font-weight: bold;
}

.Right>div:first-child {
    margin-top: 0 !important;
}

.Right>div {
    margin-top: 5rem;
}