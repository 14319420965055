.Red {
    font-size: 5rem;
    line-height: 6.5rem;
    white-space: pre-line;
    color: red
}

.Regular {
    white-space: pre-line;
    font-size: 5rem;
    line-height: 6.5rem;
    color: #828282
}