.Country {
    width: 60rem;
    height: 40rem;
}

.Status {
    font-size: 6.5rem;
    text-align: center;
}

.Time {
    margin-left: auto;
    color: #c9c9c9;
    font-size: 6rem;
    line-height: 8rem;
    text-align: center;
}

.GNHeader {
    width: 100%;
    padding: 5rem;
    display: flex;
    color: #c9c9c9;
    font-size: 6rem;
    line-height: 8rem;
}

.Line {
    margin-top: 5rem;
    margin-bottom: 5rem;
    width: calc(100% + 10rem);
    margin-left: -5rem;
    height: 1rem;
    background-color: #424242;
}