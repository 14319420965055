.Row {
    display: flex;
    padding: 6rem;
    border-radius: 3rem;
    border: 1rem solid #242424;
    background-color: #242424;
    align-items: center;
}

.Image {
    overflow: hidden;
    border-radius: 100%;
    width: 25rem;
    height: 25rem;
    background-color: #424242;
}

.Image img,
.Image svg {
    width: 100%;
    height: 100%;
}

.Text {
    margin-left: 7rem;
}

.About {
    color: #828282;
    font-size: 5.5rem;
}

.Name {
    margin-top: 2rem;
    color: #c9c9c9;
    font-size: 7rem;
}

.Box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    margin-left: auto;
    width: 10rem;
    height: 10rem;
    border: 1rem solid #424242;
    background: #2e2e2e;
}

.Box svg {
    width: 6.5rem;
    height: 6.5rem;
}

.Box svg path {
    color: white;
}

.Row.Active {
    border-color: #1971c2;
    background-color: #243441;
}

.Row.Active .Box {
    border-color: #1971c2;
    background: #1971c2;
}

.Row.Clickable {
    cursor: pointer;
}

.Row.Clickable:hover {
    opacity: 0.85;
}

.Empty {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 9rem;
    color: #828282;
    justify-content: center;
    align-items: center;
}