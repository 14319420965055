.Button {
    padding: 5rem 6rem;
    font-size: 10rem;
    line-height: 10rem;
    cursor: pointer;
    font-weight: 500;
    color: #c9c9c9;
    text-transform: uppercase;
}

.Button:hover {
    border-radius: 3rem;
    background-color: #2e2e2e
}

.ActiveButton {
    text-transform: uppercase;
    border-radius: 3rem;
    cursor: default;
    padding: 5rem 6rem;
    font-size: 10rem;
    line-height: 10rem;
    font-weight: 500;
    background-color: #1971c2;
    color: #fff;
}