.Modal {
    display: flex;
    flex-direction: column;
    padding: 6rem 0 8rem 0;
    background: #2e2e2e;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
}

.Header {
    padding: 0 8rem 6rem 8rem;
    align-items: center;
    display: flex;
    border-bottom: 1rem solid #424242;
    margin-bottom: 6rem;
}

.Title {
    font-size: 8rem;
    color: #c9c9c9;
}

.Close {
    border-radius: 3rem;
    border: 1rem solid #424242;
    background-color: #242424;
    font-weight: bold;
    margin-left: auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4rem;
    height: 15rem;
    color: #828282;
    font-size: 6.5rem;
}

.Close:hover {
    opacity: 0.8;
}

.Overflow {
    padding: 0 8rem;
    flex: 1 0 auto;
    overflow: auto;
}

.Overflow>div:first-child {
    margin-top: 0;
}

.Overflow>div {
    margin-top: 8rem;
}