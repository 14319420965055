.Wrapper >div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Wrapper img {
    border-radius: 100%;
    width: 90rem;
    height: 90rem;
}

.Name {
    color: #c9c9c9;
    font-size: 10rem;
    margin-top: 8rem;
}

.Text {
    font-size: 8rem;
    margin-top: 5rem;
    color: #828282;
}