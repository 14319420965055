.Control {
    width: auto;
    margin: 0 auto;
    display: flex;
    border: 1rem solid #424242;
    background-color: #2e2e2e;
    border-radius: 10rem;
    padding: 2rem;
}

.Point {
    flex: 1 0 auto;
    text-align: center;
    padding: 5rem;
    font-weight:  500;
    cursor: pointer;
    font-size: 8rem;
    color: #c9c9c9;
}

.Point:hover {
    color: white;
}

.Active {
    border-radius: 8rem;
    background-image: linear-gradient(to right, #c2255c, #e8590c);
    color: white;
}