.Input {
    width: 100%;
    border: 1rem solid #424242;
    background-color: #2e2e2e;
    border-radius: 3rem;
    padding: 0 4rem;
}

.Input input {
    width: 100%;
    padding: 4rem 0;
    font-size: 7.5rem;
    border: 0;
    color: #c9c9c9;
    outline: none;
    background-color: transparent;
}

.Input.Dark {
    background-color: #242424;
}