.Wrapper {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 5rem;
    width: calc(100% - 10rem);
    background-color: rgb(36, 36, 36)
}

.Line {
    height: 1rem;
    width: 100%;
    background-color: #424242;
}

.Elements {
    align-items: center;
    padding: 6rem 3rem 6rem 10rem;
    width: 100%;
    display: flex;
}

.Logo {
    font-size: 0.15rem;
}

.Title {
    display: flex;
    flex-direction: column;
    margin-left: 10rem;
    color: white;
    font-size: 16rem;
    font-weight: bold
}

.Title2 {
    color: #1971c2;
    font-size: 7rem;
    font-weight: 400
}

.Burger {
    padding: 5rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 25rem;
    position: relative;
    cursor: pointer;
    margin-left: auto;
}

.Burger span {
    margin: 4rem 0;
    height: 1rem;
    width: 100%;
    background-color: white;
}

.Burger::before {
    content: '';
    height: 1rem;
    width: 100%;
    background-color: white;
}

.Burger::after {
    content: '';
    height: 1rem;
    width: 100%;
    background-color: white;
}