.Table {
    width: 100%;
    border-collapse: collapse;
}

.Thead {
    border-bottom: 0.8rem solid #d1d0d0;
}

.Td {
    padding: 3rem 3rem 7rem 3rem;
    font-size: 7.5rem;
    font-weight: bold;
    color: #c9c9c9;
}

.Thead .Td:last-child {
    text-align: right;
}

.Tbody td:last-child {
    align-items: right;
    text-align: right;
}

.Tr {
    border-bottom: 0.8rem solid #424242;
}

.TrClick {
    cursor: pointer;
    transition: all 0.5s;
}

.Tr:hover {
    opacity: 0.7;
}