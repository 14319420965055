.Button {
    background-color: #2e2e2e;
    font-weight: bold;
    font-size: 7rem;
    cursor: pointer;
    border-radius: 3rem;
    border: 1rem solid #424242;
    margin-top: 8rem;
    width: 100%;
    padding: 6rem 0;
    text-align: center;
    color: white;
}

.Button:hover {
    background-color: #424242;
}