.Wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}

.Logo {
    font-size: 0.55rem;
    margin-bottom: 15rem
}

.Text {
    line-height: 16rem;
    text-align: center;
    font-size: 13rem;
    color: white
}

.Encoded {
    color: rgba(255, 255, 255, 0.6)
}